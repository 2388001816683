import React from "react";
import { StaticQuery, graphql, Link} from "gatsby"
//import Img from "gatsby-image"
import styled from 'styled-components'
import SectionMargin from '../Layout/Section/SectionMargin'
import Paragraph1 from "../Type/Paragraph1";
//import Grid2Auto from '../Layout/Grid2/Grid2Auto'
//import GridItem from '../Layout/GridItem/GridItem'
import Heading1 from '../Type/Heading1'
import BackgroundImage from 'gatsby-background-image'
import styles from './CustomerReviews.module.css';
import * as Markdown from 'react-markdown'




/*
fixed(width: 160, height: 160) {
  ...GatsbyContentfulFixed_noBase64
}
*/



const CustomerReviews = () => (
  <StaticQuery
    query={graphql`
    query {
      allContentfulReviews(sort: {fields: date, order: DESC}, filter: {review: {review: {ne: null}}}, limit: 3) {
        edges {
          node {
            carName
            customerName
            date(formatString: "DD/MM/YYYY")
            starRating
            review {
              review
            }
            summonReply {
              summonReply
            }
            verified
            carPhoto {
              fluid(maxWidth: 300){
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
    }
  `}
    render={data => {


      const reviews = data.allContentfulReviews.edges.map((item, i) => {

        let reviewText = item.node.review.review;
        if (reviewText.length > 100) {
          reviewText = reviewText.substring(0,100) + "..."
        }

  

      return <Card key={i} className={styles["r"+i]}>
  
                <BackgroundImage
                  fluid={item.node.carPhoto.fluid}
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '200px'
                  }}
                ><Sold>SOLD</Sold></BackgroundImage>
          
                <Container>
                  <NameBlock>
                    <div><CustomerName>{item.node.customerName}</CustomerName></div>
                    <div><Verified>Verified</Verified></div>
                  </NameBlock>
                  {/* <div>
                    {item.node.date}
                  </div> */}
                  <Stars style={{ '--rating': item.node.starRating }} />
          
                  <CarName>{item.node.carName}</CarName>
                  {item.node.review &&
                  <p>
                    <Markdown>
                     {reviewText}
                    </Markdown>  
                  </p>}
                  <div>
          
          
                  </div>
                </Container>
          
              </Card>
    });




      return (

        <SectionMargin marginTop="0px" marginBottom="0px">

          <Grid2Auto style={{ paddingTop: '50px', paddingBottom: '50px' }}>

            <GridItem order="2" >
    
                <ReviewBox>
                  {reviews}
                </ReviewBox>

            </GridItem>

            <GridItem order="1" align="right">

                <Heading1>Customer Reviews</Heading1>
                <Paragraph1>
                  We started Summon with the vision of creating a better and fairer way for 
                  Aussies to sell their used cars. <br />Are we succeeding?
                </Paragraph1>
                <Paragraph1>
                  The feedback from our customers is an overwhelming “yes”!<br /> <Link to="/reviews/">Read all reviews</Link>
                </Paragraph1>

            </GridItem>


          </Grid2Auto>

        </SectionMargin>
      )
    }}
  />
)

export default CustomerReviews;






const Grid2Auto = styled.div`
  display: grid;
  grid-template-columns: auto 300px;
  column-gap: 20px;
  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }

`;

const GridItem = styled.div`
    text-align: ${props => props.align};
    @media (max-width: 1200px) {
      text-align: left;
      grid-row: ${props => props.order}
    }
`;  


const ReviewBox = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 390px;
`;

//   



const Card = styled.div`

  background-color: #ffffff;
  color: #000000;
  width: 300px;
  margin: 0px;
  padding: 0px;

  
 
`;

const Container = styled.div`
  padding: 10px 20px 10px 20px;
  background: #f8f8f9;
  
  
  p {
    margin-top: 0;
    line-height: 1.7rem;
    letter-spacing: -0.05rem;
  }
`;

const CarName = styled.div`
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 6px;
`;

const CustomerName = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
`;


const NameBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

// const RatingBar = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   margin-bottom: 20px;

//   @media (max-width: 700px) {
//     display: block;
// 	} 
// `;


const Sold = styled.div`
  position: absolute;
  right: 0px;
  margin: 10px;
  padding: 2px;
  color: white;
  background: #3479ae;
  text-align: center;
  width: 70px;
  font-weight: 600;
  font-size: .9rem;
 
`;
const Verified = styled.div`
  padding: 2px;
  color: white;
  background: black;
  text-align: center;
  width: 70px;
  font-size: .8rem;
`;

const Stars = styled.div`
  --percent: calc(var(--rating) / 5 * 100%);
  
  display: inline-block;
  font-size: 1.7rem;
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;
  // fa8870 orange
  // #3479ae blue

  &::before {
    content: '★★★★★';
    letter-spacing: 1px;
    background: linear-gradient(90deg, #fa8870 var(--percent), #d6d6d6 var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
