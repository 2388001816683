import React from "react";
import { StaticQuery, graphql, Link, navigate } from "gatsby"
//import Img from "gatsby-image"
import styled from 'styled-components'
import SectionMargin from '../Layout/Section/SectionMargin'
import Paragraph1 from "../Type/Paragraph1";
//import Grid2Auto from '../Layout/Grid2/Grid2Auto'
//import GridItem from '../Layout/GridItem/GridItem'
import Heading1 from '../Type/Heading1'
import BackgroundImage from 'gatsby-background-image'
import styles from './RecentListingsBar.module.css';




/*
fixed(width: 160, height: 160) {
  ...GatsbyContentfulFixed_noBase64
}
*/



const RecentListings = () => (
  <StaticQuery
    query={graphql`
        query  {
          allContentfulCarListing(filter: {sold: {eq: false}}, sort: {fields: createdAt, order: DESC}, limit: 6) {
            edges {
              node {
                carName
                slug
                pipedriveDealId
                listingIndexImage {
                  fluid (maxWidth: 400) {
                    ...GatsbyContentfulFluid_noBase64
                    }
                }
              }
            }
          }
        }
  `}
    render={data => {

 
      let vaildImages = data.allContentfulCarListing.edges.filter((item) => item.node.listingIndexImage !== null);
      // limit vaildImages to 4
      vaildImages = vaildImages.slice(0, 4);

      
      //const listings = data.allContentfulCarListing.edges.map((item, i) =>
      const listings = vaildImages.map((item, i) =>
        <Card 
          key={i} 
          className={`${styles.bgimage}`} 
          onClick={() => navigate('/listings/' + item.node.slug + "-" + item.node.pipedriveDealId + "/")}>
          

          <BackgroundImage
            fluid={item.node.listingIndexImage.fluid}
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '100%',
              height: '100%'
            }}
          />

         
        </Card>
      );


      return (

        <SectionMargin bgColor="#f8f8f9" marginTop="0px" marginBottom="0px">

          <Grid2Auto style={{ paddingTop: '50px', paddingBottom: '50px' }}>

            <GridItem order="2" >
    
                <ImgBox>
                  {listings}
                </ImgBox>

            </GridItem>

            <GridItem order="1" align="right">

                <Heading1>Current listings</Heading1>
                <Paragraph1>
                  We are 100% focused on prestige car sales, here are some of our most recent listings. <Link to="/recent-listings/">See all cars for sale</Link>
                </Paragraph1>

            </GridItem>


          </Grid2Auto>

        </SectionMargin>
      )
    }}
  />
)

export default RecentListings




const Card = styled.div`
  display: flex;
  margin: 0px;
  padding: 0px;
  flex-basis: 45%;
  height: 150px;
  border: solid white 7px;
  margin-right: 5px;
  margin-bottom: 15px;

  @media (max-width: 450px) {
    flex-basis: 100%;

  }



`;

const Grid2Auto = styled.div`
  display: grid;
  grid-template-columns: auto 320px;
  column-gap: 20px;
  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }

`;

const GridItem = styled.div`
    text-align: ${props => props.align};
    @media (max-width: 1200px) {
      text-align: left;
      grid-row: ${props => props.order}
    }
`;  


const ImgBox = styled.div`
 display: flex;
 flex-wrap: wrap;
  justify-content: center;

`;

//   