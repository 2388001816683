import React from "react"
import styled from 'styled-components'

const Heading1 = ({ color, children }) => {
   if(!color) {
       color = "black";  
   }
    return (
        <Heading colour={color}>{children}</Heading>
  )
} 

export default Heading1;

const Heading = styled.h1`
    color: ${props => props.color};
    font-size: 3rem;
    font-weight: 600;
    line-height: 3rem;
    letter-spacing: -0.1rem;
    margin-bottom: 0px;
`
