import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import HomeIntro from '../components/HomeIntro/HomeIntro'
// import SummonApp from '../components/SummonApp/SummonApp'
import Benefits from '../components/Benefits/Benefits'
//import Quote from '../components/Quotes/Quotes'
import ImageCompare from '../components/ImageCompare/ImageCompare'
import BestOfBothWorlds from '../components/BestOfBothWorlds/BestOfBothWorlds'
//import SellingPowers from '../components/SellingPowers/SellingPowers'
import ReadyToGetStartedButton from '../components/ReadyToGetStarted/ReadyToGetStartedButton'
import SectionMargin from '../components/Layout/Section/SectionMargin'
//import QuickContact from '../components/QuickContact/QuickContact'
//import BragBar from '../components/BragBar/BragBar'
import ValuationPromoBlock from '../components/Valuation/ValuationPromoBlock'
import AwardsBlock from "../components/Awards/AwardsBlock"
import PressBar from "../components/PressBar/PressBar"
import SpecialOffer from "../components/SpecialOffer/SpecialOffer"
import RecentListings from "../components/RecentListings/RecentListingsBar"
import ThreeBenefits from "../components/ThreeBenefits/ThreeBenefits"
import CustomerReviews from "../components/CustomerReviews/CustomerReviews"

const IndexPage = () => (
  <Layout>
    
    <SEO  title="Summon - the better way to sell your prestige car" 
          description="Summon is a full concierge service for selling your car. We will collect, 
          clean and photograph and list your car for sale, field phone calls and enquiries, 
          collect the car and take the test drives, help negotiate and facilitate a safe and 
          reliable funds transfer to you. We’ll also take care of the Roadworthy and 
          transfer of ownership."
    />


    <HomeIntro />
  
    <BestOfBothWorlds />

    {/* <SpecialOffer /> */}

    <CustomerReviews />

    {/* <SellingPowers /> */}

    <ThreeBenefits />

    <ImageCompare />
{/* 
    <BragBar /> */}

    {/* <QuickContact /> */}

    {/* <Quote /> */}

    {/* <SummonApp /> */}

    <RecentListings />

    <AwardsBlock />

    <PressBar />
  
    <Benefits />

    <SectionMargin>
      <div style={{width: '100%'}}>
        <ReadyToGetStartedButton />
      </div>
    </SectionMargin>

    <ValuationPromoBlock />

  </Layout>
)

export default IndexPage
