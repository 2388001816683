import React from "react"
import { StaticQuery, graphql, navigate, Link } from "gatsby"
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Heading2 from '../Type/Heading2'
import Header from "../Header/Header"
import SectionMargin from '../Layout/Section/SectionMargin'
import Button from '../Layout/Button/Button'

const BackgroundSection = ({ className }) => (
	<StaticQuery
		query={graphql`
			query {
				placeholderImage: file(relativePath: { eq: "intro_background_dark.png" }) {
					childImageSharp {
					fluid(maxWidth: 1650, quality: 80) {
						...GatsbyImageSharpFluid
						
					}
					}
				}

				allContentfulReviews(sort: {fields: date, order: DESC}) {
					edges {
					  node {
						starRating
					  }
					}
				  }

			}
    	`}
		render={data => {

			const handleClick = () => {
				navigate('/sell-with-summon/');
			};

			let totalReviews = data.allContentfulReviews.edges.length;
			let sum = 0;
			for (var i = 0; i < totalReviews; i++) {
				sum += data.allContentfulReviews.edges[i].node.starRating;
				data.allContentfulReviews.edges[i].node.order = i;
			}
			let avgRating = sum / totalReviews;
			avgRating = Math.round(avgRating * 10) / 10;



			return (

				<IntroBlock>
					<BackgroundImage
						fluid={data.placeholderImage.childImageSharp.fluid}
						style={{
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							width: '100%',
							height: '100%'
						}}
					>
						<Header hideBG={true}/>

						<SectionMargin>
							<div style={{ width: '100%' }}>
								<IntroMessage>
									<Heading2 color="white">
										Sell your prestige car
										with our concierge service.<br />
										Great value, no hassles.
									</Heading2>
								</IntroMessage>
								<IntroText>
									<p>
										Too busy to sell your prestige car, but don’t want to accept a low dealer trade-in price?
									</p>
									<p>
										Summon removes the hassle of selling privately, and you could get a much better price than selling to a dealer.
									</p>
									<ButtonBlock>
										<Button onClick={handleClick}>Get Started</Button>
										<div style={{ marginLeft: '20px', fontSize: '.9rem' }}>
											<Stars style={{ '--rating': avgRating }} /><br />
		   									{avgRating}/5 in <Link to="/reviews/" style={{ color: 'white' }}>customer reviews</Link>
										</div>
									</ButtonBlock>



								</IntroText>
							</div>
						</SectionMargin>
					</BackgroundImage>
				</IntroBlock>
			)
		}}
	/>
)

const IntroBlock = styled.div`
	width: 100%;
	height: 650px; 
	background: black;
	display: flex; 
	justify-content: center;
	margin-top: -72px;

	@media (max-width: 850px) {
		height: 750px; 
	} 

	@media (max-width: 500px) {
		height: 800px; 
	} 

`;


const ButtonBlock = styled.div`
	display: flex;
	margin-top: 30px;
`;

const IntroMessage = styled.div`
	padding-top: 50px;
	margin-bottom: 10px;
	margin-top: 0px;
	max-width: 500px;

`;

const IntroText = styled.div`
	font-size: 1.5rem;
	color: white;
	letter-spacing: -1px;
	max-width: 670px;
`

const StyledBackgroundSection = styled(BackgroundSection)`
    margin: 0px;
`
const Stars = styled.div`
  --percent: calc(var(--rating) / 5 * 100%);
  
  display: inline-block;
  font-size: 1.5rem;
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;
  // fa8870 orange
  // #3479ae blue

  &::before {
    content: '★★★★★';
    letter-spacing: 1px;
    background: linear-gradient(90deg, #fbbb04 var(--percent), #d6d6d6 var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export default StyledBackgroundSection
