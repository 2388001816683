import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
//import { Link } from "gatsby"

import SectionMargin from '../Layout/Section/SectionMargin'
import GridItem from '../Layout/GridItem/GridItem'
import Heading1 from '../Type/Heading1'
import Paragraph1 from '../Type/Paragraph1';

const AwardsBlock = () => (
  <StaticQuery
    query={graphql`
      query  {

        designImg: file(relativePath: { eq: "awards/design-driven-logo.png" }) {
          childImageSharp {
            fixed(width: 200) {
            ...GatsbyImageSharpFixed
            }
          }
        }
    
        oriasImg: file(relativePath: { eq: "awards/orias-logo.png" }) {
          childImageSharp {
            fixed(width: 200) {
            ...GatsbyImageSharpFixed
            }
          }
        }

        finderImg: file(relativePath: { eq: "awards/finder-web-badge.png" }) {
          childImageSharp {
            fixed(width: 140) {
            ...GatsbyImageSharpFixed
            }
          }
        }

        goodImg: file(relativePath: { eq: "awards/good-design-awards.png" }) {
          childImageSharp {
            fixed(width: 160) {
            ...GatsbyImageSharpFixed
            }
          }
        }


       

      }
    `}
    render={data => {

      return (
        <>
        <SectionMargin marginBottom="0px" >
          <Grid2Auto style={{ paddingTop: '40px'}}>
            <GridItem order="1" >
              <Heading1>Awards</Heading1>
            </GridItem>
            <GridItem order="2" >
              <Paragraph1>
              We are thrilled to be recognised by the retail and design community 
              for the innovative service we are providing to Australian car owners.
              </Paragraph1>
            </GridItem>
          </Grid2Auto>
        </SectionMargin>
        <SectionMargin >
          <LogoBlock>
            <Award>
              <Logo>
                <Img fixed={data.designImg.childImageSharp.fixed} style={{ display: 'block' }} />
              </Logo>
              <AwardTitle>GOLD AWARD</AwardTitle>
              <AwardDescription>Digital - New Service or Application</AwardDescription>
            </Award>
            <Award>
              <Logo>
                <Img fixed={data.oriasImg.childImageSharp.fixed} style={{ display: 'block' }} />
              </Logo>
              <AwardTitle>FINALIST</AwardTitle>
              <AwardDescription>Best Phygital Initiative</AwardDescription>
              <AwardDescription>Best Small Independent Retailer</AwardDescription>
            </Award>
            <Award>
              <Logo>
                <Img fixed={data.finderImg.childImageSharp.fixed} style={{ display: 'block'}} />
              </Logo>
              <AwardTitle>FINALIST</AwardTitle>
              <AwardDescription>Best Retail Innovation</AwardDescription>
              <AwardDescription>Best Automotive Innovation</AwardDescription>
            </Award>
            
            <Award>
              <Logo>
                <Img fixed={data.goodImg.childImageSharp.fixed} style={{ display: 'block' }} />
              </Logo>
              <AwardTitle>WINNER</AwardTitle>
              <AwardDescription>Best Service Design,</AwardDescription>
              <AwardDescription>Commercial Services</AwardDescription>
            </Award>

          </LogoBlock>
        </SectionMargin>

        </>

        
      )
    }}
  />
)

export default AwardsBlock

const LogoBlock = styled.div`
    display: flex;

    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 20px;

    @media (max-width: 600px) {
      justify-content: center;
  
    }
`;

const Award = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    width: 200px;
    height: 200px;
    text-align: center;
`;

const Logo = styled.div`
    display: flex;
    justify-content: center;



    height: 100px;

    @media (max-width: 800px) {
     height:auto;
     width: auto;
     margin-bottom: 10px;
  
    }
`;

const AwardTitle = styled.div`
    font-weight: 600;
    font-size: .9rem;
`;
const AwardDescription= styled.div`
    font-size: .9rem
`;

const Grid2Auto = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 40px;

  @media (max-width: 800px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;

  }

`;



//justify-content: center;
//align-items: center; 
//height: 400px;


