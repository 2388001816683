import React from "react"
import styled from 'styled-components'

const SectionMargin  = ({ bgColor, children, marginTop, marginBottom }) => {
  if(!marginTop) marginTop = "0px";
  if(!marginBottom) marginBottom = "100px";

  return (
    <>
        <Wrapper bgColor={bgColor} marginTop={marginTop} marginBottom={marginBottom}>
            <Content>{children}</Content>
        </Wrapper>
    </>
  )
} 

export default SectionMargin

const Wrapper = styled.section`
    display: grid;
    margin-top:  ${props => props.marginTop};
    margin-bottom:  ${props => props.marginBottom};
    grid-template-columns: minmax(400px, 1300px);
    background-color: ${props => props.bgColor};
    justify-content: center;

`;

const Content = styled.div`
    margin-left: 200px;
    margin-right: 200px;
    display: flex;
    justify-content: center;

    @media (max-width: 800px) {
      margin-left: 30px;
      margin-right: 30px;
    }
`;
