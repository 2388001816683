import React from "react"
import SectionMargin from '../Layout/Section/SectionMargin'
import Grid2 from '../Layout/Grid2/Grid2'
import GridItem from '../Layout/GridItem/GridItem'
import {useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'
import Heading1 from '../Type/Heading1'
import Paragraph1 from '../Type/Paragraph1'
import Button from '../Layout/Button/Button'
//import StyledLink from '../Type/StyledLink'

const BestOfBothWorlds = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "best-of-both-worlds.png" }) {
        childImageSharp {
          fluid(maxWidth: 478) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleClick = () => {
    navigate('https://value.summon.com.au//');
  };

    return (
        <SectionMargin bgColor="white" marginTop="60px">
            <Grid2>
                <GridItem order="1">
                    <Heading1>Yes, the best of both worlds</Heading1>
                    <Paragraph1>
                        Finally it’s possible to combine the ease of selling your car to 
                        a dealer, with the value you get from selling privately. Why compromise, 
                        when you can summon us?
                    </Paragraph1>
  
                      <Flex>
                        <Button onClick={handleClick}>Request valuation</Button>
                        {/* <Spacer />
                        <StyledLink to="/articles/calculator/">
                          Calculate <span style={{whiteSpace: "nowrap"}}>your savings</span>
                        </StyledLink> */}
                      </Flex>
                </GridItem>
                <GridItem order="2">
                  <ImageBox>
                    <Img fluid={data.placeholderImage.childImageSharp.fluid} />
                  </ImageBox>
                    
                </GridItem>
            </Grid2>
        </SectionMargin> 
    )
}

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

// const Spacer = styled.div`
//     width: 25px;
// `;

const ImageBox = styled.div`
  margin-top: 0px;
  @media (max-width: 800px) {
    margin-top: 40px;
  }
`

export default BestOfBothWorlds