import React from "react"
import styled from 'styled-components'

const Heading2 = ({ color, children }) => {
   if(!color) {
       color = "black";  
   }
    return (
        <Heading color={color}>{children}</Heading>
  )
} 

export default Heading2;

const Heading = styled.h2`
    color: ${props => props.color};
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 600;
    letter-spacing: -0.1rem;
    margin-bottom: 0px;
`
