import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import SectionMargin from '../Layout/Section/SectionMargin'

const Month = [
    ["January 31st", "JAN"],
    ["February 28th", "FEB"],
    ["March 31st", "MAR"],
    ["April 30th", "APR"],
    ["May 31st", "MAY"],
    ["June 30th", "JUN"],
    ["July 31st", "JUL"],
    ["August 31st", "AUG"],
    ["September 30th", "SEP"],
    ["October 31st", "OCT"],
    ["November 30th", "NOV"],
    ["December 31st", "DEC"]
];

var d = new Date();
var n = d.getMonth();
var dayOfMonth = d.getDate();

const offer = props => {

    // only show second half of month
    if(dayOfMonth > 14) {
        return (

            <SectionMargin bgColor="#3479ae" marginTop="0px" marginBottom="0px">
                <OfferText>
                    <strong>SPECIAL OFFER</strong>: Request a <StyledLink to="https://value.summon.com.au//">valuation today</StyledLink> and 
                    sign-up <strong>before {Month[n][0]}</strong> to receive $250 saving. Quote code {Month[n][1]}-250
                </OfferText>
            </SectionMargin>
        )
    } else {
        return null;
    }

};

export default offer;

const OfferText = styled.div`
    color: white;
    letter-spacing: -0.5px;
    margin: 10px 0px 10px 0px;
`;


const StyledLink = styled(Link)`
 color: white;
 &:hover {
    color: #c7edfb;
  }




`

