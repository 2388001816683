import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
//import { Link } from "gatsby"

import SectionMargin from '../Layout/Section/SectionMargin'
// import GridItem from '../Layout/GridItem/GridItem'
// import Heading1 from '../Type/Heading1'
// import Paragraph1 from '../Type/Paragraph1';

const AwardsBlock = () => (
  <StaticQuery
    query={graphql`
      query  {

        dollarImg: file(relativePath: { eq: "dollars_bar.png" }) {
          childImageSharp {
            fixed(width: 90) {
            ...GatsbyImageSharpFixed
            }
          }
        }
    
        targetImg: file(relativePath: { eq: "target_bar.png" }) {
          childImageSharp {
            fixed(width: 70) {
            ...GatsbyImageSharpFixed
            }
          }
        }

        strattonImg: file(relativePath: { eq: "stratton_bar.png" }) {
          childImageSharp {
            fixed(width: 160) {
            ...GatsbyImageSharpFixed
            }
          }
        }


       

      }
    `}
    render={data => {

      return (
        <>

        <SectionMargin bgColor="#f8f8f9" marginTop="10px">
          <FeatureBlock>

            <Feature>
              <Logo>
                <Img fixed={data.dollarImg.childImageSharp.fixed} style={{ display: 'block' }} />
              </Logo>
              <Title>15% above Trade-in</Title>
              <Description>Our average sale price is 15% higher than a Dealer trade-in price</Description>
            </Feature>

            <Feature>
              <Logo>
                <Img fixed={data.targetImg.childImageSharp.fixed} style={{ display: 'block' }} />
              </Logo>
              <Title>100% Money Back Guarantee</Title>
              <Description>If we cannot sell your car within 90 days, within the agreed listing range.  No sale, no fee. </Description>
            </Feature>

            <Feature>
              <Logo>
                <Img fixed={data.strattonImg.childImageSharp.fixed} style={{ display: 'block'}} />
              </Logo>
              <Title>Finance partners</Title>
              <Description>We can arrange competitive finance packages thanks to our partnership with Stratton Finance.</Description>
            </Feature>

          </FeatureBlock>
        </SectionMargin>

        </>

        
      )
    }}
  />
)

export default AwardsBlock

const FeatureBlock = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 20px;

    @media (max-width: 600px) {
      justify-content: center;
  
    }
`;

const Feature = styled.div`
    width: 280px;
    height: 200px;

    text-align: center;
`;

const Logo = styled.div`
    display: flex;
    justify-content: center;
    height: 80px;

`;

const Title = styled.div`
    font-weight: 600;
    font-size: .9rem;
    margin-bottom: 7px;
`;
const Description= styled.div`
    font-size: .9rem
`;

// const Grid2Auto = styled.div`
//   display: grid;
//   grid-template-columns: auto auto;
//   column-gap: 40px;

//   @media (max-width: 800px) {
//     grid-template-columns: 100%;
//     grid-template-rows: auto auto;

//   }

// `;



//justify-content: center;
//align-items: center; 
//height: 400px;


