import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import { Link } from "gatsby"

import SectionMargin from '../Layout/Section/SectionMargin'
import GridItem from '../Layout/GridItem/GridItem'
import Heading1 from '../Type/Heading1'
import Paragraph1 from '../Type/Paragraph1';

const ValuationPromoBlock = () => (
  <StaticQuery
    query={graphql`
      query  {

        audiImg: file(relativePath: { eq: "car-logos/audi.png" }) {
          childImageSharp {
            fixed(width: 150) {
            ...GatsbyImageSharpFixed
            }
          }
        }
    
        bmwImg: file(relativePath: { eq: "car-logos/bmw.png" }) {
          childImageSharp {
            fixed(width: 150) {
            ...GatsbyImageSharpFixed
            }
          }
        }

        mercedesBenzImg: file(relativePath: { eq: "car-logos/mercedes-benz.png" }) {
          childImageSharp {
            fixed(width: 150) {
            ...GatsbyImageSharpFixed
            }
          }
        }
    
        porscheImg: file(relativePath: { eq: "car-logos/porsche.png" }) {
          childImageSharp {
            fixed(width: 150) {
            ...GatsbyImageSharpFixed
            }
          }
        }

      }
    `}
    render={data => {

      return (
        <>
        <SectionMargin bgColor="#f8f8f9" marginBottom="0px">
          <Grid2Auto style={{ paddingTop: '40px'}}>
            <GridItem order="1" >
              <Heading1>Prestige car <br /> price guides</Heading1>
            </GridItem>
            <GridItem order="2" >
              <Paragraph1>
                If you are researching the value of your prestige used car, we are happy to offer
                          a <Link to="https://value.summon.com.au//" title="Free car valuation">free no-obligation valuation</Link>,
                          or you can browse our new Price Guides. We have price guides for the brands below, as
                          well for <Link to="/price/land-rover" title="Land Rover Price Guide">Land Rover</Link>
                          , <Link to="/price/maserati" title="Maserati Price Guide">Maserati</Link>
                          , <Link to="/price/tesla" title="Tesla Price Guide">Tesla</Link>
                          , <Link to="/price/toyota/landcruiser" title="Toyota Landcruiser Price Guide">Toyota Landcruiser</Link> and <Link to="/price/volvo" title="BMW Price Guide">Volvo</Link>
              </Paragraph1>
            </GridItem>
          </Grid2Auto>
        </SectionMargin>
        <SectionMargin bgColor="#f8f8f9" marginBottom="0px">
          <LogoBlock>
            <MakeLink to={"/price/audi/"} title="Audi Price Guide">
              <Img fixed={data.audiImg.childImageSharp.fixed} style={{ display: 'block' }} />
              <span>Audi</span>
            </MakeLink>
            <MakeLink to={"/price/bmw/"} title="BMW Price Guide">
              <Img fixed={data.bmwImg.childImageSharp.fixed} style={{ display: 'block' }} />
              <span>BMW</span>
            </MakeLink>
            <MakeLink to={"/price/mercedes-benz/"} title="Mercedes-Benz Price Guide">
              <Img fixed={data.mercedesBenzImg.childImageSharp.fixed} style={{ display: 'block' }} />
              <span>Mercedes-Benz</span>
            </MakeLink>
            <MakeLink to={"/price/porsche/"} title="Porsche Price Guide">
              <Img fixed={data.porscheImg.childImageSharp.fixed} style={{ display: 'block' }} />
              <span>Porsche</span>
            </MakeLink>
          </LogoBlock>
        </SectionMargin>

        </>

        
      )
    }}
  />
)

export default ValuationPromoBlock

const LogoBlock = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;

    @media (max-width: 600px) {
      justify-content: center;
  
    }
`;

const MakeLink = styled(Link)`
	text-align: center;
	padding: 0px 10px 10px 10px;
	margin: 0px 10px 10px 10px;
`

const Grid2Auto = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 40px;

  @media (max-width: 800px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;

  }

`;



//justify-content: center;
//align-items: center; 
//height: 400px;


