import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Collapsible from 'react-collapsible';
import * as Markdown from 'react-markdown';
import SectionMargin from '../Layout/Section/SectionMargin'
import Grid2 from '../Layout/Grid2/Grid2'
import GridItem from '../Layout/GridItem/GridItem'
import Heading1 from '../Type/Heading1'
import Paragraph1 from '../Type/Paragraph1'

import './Benefits.css';

const Benefits = () => (
	<StaticQuery
		query={graphql`
    query  {
      allContentfulFeature(filter: {contentful_id: {eq: "50WjpMMbBrlY0BxbgVaAUM"}}) {
        edges {
          node {
            title
            text {
              text
            }
            contentReference {
              ... on ContentfulBenefits {
                benefit {
                  benefit
                }
                title
              }
            }
          }
        }
      }
    }
    `}
		render={data => {

			// const title = data.allContentfulFeature.edges[0].node.title;
			//const text = data.allContentfulFeature.edges[0].node.text.text;
			const benefits = data.allContentfulFeature.edges[0].node.contentReference.map((item, i) =>
				<Collapsible classParentString="Bene-Collapsible" key={i} trigger={(i+1) + '. ' + item.title}>
					<Markdown source={item.benefit.benefit} />
				</Collapsible>
			);

			return (

				<React.Fragment>
					<SectionMargin marginBottom="20px">
						<Grid2>
							<GridItem order="1">
								<Heading1>Summon in summary</Heading1>
								<Paragraph1>
									Below is an outline of the process (a “summonary”?) of selling
									with Summon, and everything we do to help you sell your car for
									the best possible price. Expand each row to see more detail about
									each of the steps.
							</Paragraph1>
							</GridItem>
							<GridItem order="2">
							</GridItem>
						</Grid2>
					</SectionMargin>
					<SectionMargin>
						<Wrapper>
							{benefits}
						</Wrapper>
					</SectionMargin>
				</React.Fragment>



			)
		}}
	/>
)

const Wrapper = styled.div`
  display: block;
  li {
	  padding-bottom: 15px;
  }
`;



export default Benefits
