import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
//import { Link } from "gatsby"

import SectionMargin from '../Layout/Section/SectionMargin'
// import GridItem from '../Layout/GridItem/GridItem'
// import Heading1 from '../Type/Heading1'
// import Paragraph1 from '../Type/Paragraph1';

const PressBlock = () => (
  <StaticQuery
    query={graphql`
      query  {

        australianImg: file(relativePath: { eq: "press/the-australian.png" }) {
          childImageSharp {
            fixed(width: 180) {
            ...GatsbyImageSharpFixed
            }
          }
        }
    
        evoImg: file(relativePath: { eq: "press/evo.png" }) {
          childImageSharp {
            fixed(width: 180) {
            ...GatsbyImageSharpFixed
            }
          }
        }

        startupImg: file(relativePath: { eq: "press/start-up-daily.png" }) {
          childImageSharp {
            fixed(width: 180) {
            ...GatsbyImageSharpFixed
            }
          }
        }

        smartImg: file(relativePath: { eq: "press/smart-company.png" }) {
          childImageSharp {
            fixed(width: 180) {
            ...GatsbyImageSharpFixed
            }
          }
        }


       

      }
    `}
    render={data => {

      return (
        <>

        <SectionMargin bgColor="#f8f8f9" marginTop="-80px" marginBottom="100px">
          <LogoBlock>
            <Press>
              <Logo>
                <Img fixed={data.australianImg.childImageSharp.fixed} style={{ display: 'block' }} />
              </Logo>
              <Quote>“... sold over 85 cars to the total value of $8 million.”</Quote>
            </Press>
            <Press>
              <Logo>
                <Img fixed={data.evoImg.childImageSharp.fixed} style={{ display: 'block' }} />
              </Logo>
              <Quote>“...the sale and test drive process is handled professionally.” </Quote>
            </Press>
            <Press>
              <Logo>
                <Img fixed={data.startupImg.childImageSharp.fixed} style={{ display: 'block', margin: '0 auto' }} />
              </Logo>
              <Quote>“a full ecommerce service for prestige cars”</Quote>
            </Press>

            <Press>
              <Logo>
                <Img fixed={data.smartImg.childImageSharp.fixed} style={{ display: 'block', margin: '0 auto' }} />
              </Logo>
              <Quote>“It’s 2021, the era of remote work and ecommerce...”</Quote>
            </Press>
          </LogoBlock>
        </SectionMargin>

        </>

        
      )
    }}
  />
)

export default PressBlock

const LogoBlock = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: 600px) {
      justify-content: center;
  
    }
`;

const Press = styled.div`
    width: 180px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
`;

const Logo = styled.div`
    width: 180px;
    height: 80px;
`;


const Quote= styled.div`
    font-size: .9rem
`;

