import React from 'react';
import { Link, navigate } from "gatsby"
import styled from 'styled-components'

const QualifyBlock = styled.div`
    background: #3479ae;
    padding-left: 10px;
    padding-top: 0px;
    padding-bottom: 15px;

    width: 100%;
    max-width: 400px;

    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }

`;

const TitleH4 = styled.div `
    display: block;
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0px;
    padding: 15px 15px 4px 10px;
`;

const StyledLink = styled(Link) `
    color: white;
    text-decoration: underline;
    padding: 0px 15px 8px 10px;
}

`;

const ReadyToGetStartedButton = () => (
    <QualifyBlock  onClick={() => navigate('/enquiry/')}>
        <TitleH4>Ready to get started?</TitleH4>
        <StyledLink to="/enquiry/">Tell us about your car</StyledLink>
    </QualifyBlock>
)

export default ReadyToGetStartedButton