import React from 'react';
import SectionMargin from '../Layout/Section/SectionMargin'
import {navigate } from "gatsby"
import Grid2 from '../Layout/Grid2/Grid2'
import GridItem from '../Layout/GridItem/GridItem'
import styled from 'styled-components'
import Heading1 from '../Type/Heading1'
import Paragraph1 from '../Type/Paragraph1'
import Button from '../Layout/Button/Button'
import ReactCompareImage from './ReactImageCompare';
import image1 from './image1.jpg'; 
import image2 from './image2.jpg'; 

const ImageCompare = () => {
    const handleClick = () => {
        navigate('/sell-with-summon/');
      };
    return (
        <SectionMargin bgColor="white">
                <Grid2>
                    <GridItem order="1">
                        <Heading1>Your car deserves to look this good</Heading1>
                        <Paragraph1>
                            We work with leading automotive photographers and specialist car detailers 
                            to make sure every customer’s car looks incredible. Your car deserves the best, 
                            and buyers will drool over it. 
                        </Paragraph1>
                        <Paragraph1>
                            Love your car but need to set it free?
                        </Paragraph1>
                        <Button onClick={handleClick}>Find out how it works</Button>
                    </GridItem>
                    <GridItem order="2">
                    <ImageBox>
                        <ReactCompareImage 
                            leftImage={image1} 
                            rightImage={image2}  
                            sliderLineWidth={4} 
                            sliderLineColor="#3479ae"
                            leftImageLabel="Typical Photo"
                            rightImageLabel="Summon Photo"
                            /> 
                    </ImageBox>
                    </GridItem>
                </Grid2>
            </SectionMargin> 
        )
}

const ImageBox = styled.div`
  margin-top: 0px;
  @media (max-width: 800px) {
    margin-top: 40px;
  }
`

export default ImageCompare;


