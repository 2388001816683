import styled from 'styled-components';

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
  bgColor: props.bgColor || '#3479ae'
}))`
background-color: ${props => props.bgColor};
border: none;
color: white;
padding: 10px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 0.9rem;
font-weight: normal;
margin: 2px 2px 2px 2px;
padding-left: 20px;
padding-right: 20px;
border-radius: 5px;
cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`
export default Button;